import React from "react"

import footerStyles from './footer.module.css'

const Footer = ({ siteAuthor }) => {
  return (
    <footer className={footerStyles.footer}>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby </a>
      <a className="{footerStyles.greenText}" href="http://localhost:8000">By {siteAuthor}</a>
    </footer>
  )
}

export default Footer