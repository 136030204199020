import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import headerStyles from "./header.module.css"

const Header = ({ siteTitle }) => (
  <header className={headerStyles.header}>
    <h1>
      <Link className={headerStyles.title} to="/">{siteTitle}</Link>
    </h1>
    <nav>
      <ul className={headerStyles.navList}>
        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">
          Home
        </Link>
        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">
          Blog
        </Link>
        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/projects">
          Projects
        </Link>
        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">
          Contact
        </Link>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
